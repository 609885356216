class commonHelper{
	constructor() {
		
	}
	formLabels() {
        var inputs = $('.fancy-label input');
        $(inputs).focus(function(event) {
            var input = event.target;
            var label = $(input).parent().parent().find('label');
            $(label).addClass('focused');
        })
        $(inputs).focusout(function(event) {
            var input = event.target;
            var label = $(input).parent().parent().find('label');

            if ($(input).val().length == 0 ) {
                $(label).removeClass('focused');
            }
        })
    }
    
    checkMotionSettings() {
        var mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
		return mediaQuery.matches;
    }
	pauseVideo() {
		$('#pauseLoop').on('click', function() {
			$('.hero-video').hide();
			$('#pauseLoop').hide();
			$('#playLoop').show();
		});
	}
	playVideo() {
		$('#playLoop').on('click', function() {
			$('.hero-video').show();
			$('#pauseLoop').show();
			$('#playLoop').hide();
		});
	}
	alerts() {
		$('#footer-accordion').on('shown.bs.collapse', function () {
			scrollDest = parseInt($('#footerform').offset().top);
			$('html, body').animate({
				scrollTop: scrollDest,
			}, 500);
		 });
	}
}
window.app.add('common', new commonHelper());