class animateHelper {
	constructor() {
		this.end = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
	}

	init() {
	}

	transition(target, animation, phase, endClass) {
		$(target).addClass([animation, endClass]).one(this.end, () => {
			$(target).removeClass(phase == 'in' ? animation : [animation, endClass])
		})
	}

	backgroundAnimate() {
		var reduceMotion = window.app.common.checkMotionSettings();
	
		if (!reduceMotion && $(window).width() > 992) {
			$(window).scroll(function(){    
				var topDistance = $(this).scrollTop();
				var layers = document.querySelectorAll("[data-type='parallax']");
				for (i = 0; i < layers.length; i++) {
					var layer = layers[i];
					var depth = layer.getAttribute('data-depth');
					var movement = -(topDistance * depth);
					var translate3d = 'translate3d(0, ' + movement + 'px, 0)';
					layer.style['-webkit-transform'] = translate3d; 
					layer.style['-moz-transform'] = translate3d;
					layer.style['-ms-transform'] = translate3d;
					layer.style['-o-transform'] = translate3d;
					layer.style.transform = translate3d;

				}
			});
		}
	}
}

window.app.add('animate', new animateHelper());