class scrollHelper{
	constructor() {
		console.log('called');
		document.querySelectorAll("a[href*='#']").forEach(item => {
			item.addEventListener('click', function() {
				var target = this.hash;
				$('html, body').animate({
				'scrollTop': $(target).offset().top-150
				}, 900);
				$(target).focus();
			});
	
		});
	}
	to(el, tar, buff) {
		$(el).click(function (e) {
			e.preventDefault();
			var target = typeof tar === 'undefined' ? $(this).attr('href') : tar,
				buffer = typeof buff === 'undefined' ? parseInt($('body').css('margin-top')) : buff,
				scrollDest = parseInt($(target).offset().top - buffer);
			$('html, body').animate({
				scrollTop: scrollDest,
			}, 1000);
		});
	}
	nav() {
		if ($(window).width() > 992) {
			$(window).scroll(function(){    
				if ($(this).scrollTop() > 100) {
					$('#navbarCollapse').css('animation', 'fadeOut .5s ease forwards');
					$('#navbarScroll').css('animation', 'fadeIn .5s ease forwards');
				} else {
					$('#navbarScroll').css('animation', 'fadeOut .5s ease forwards');
					$('#navbarCollapse').css('animation', 'fadeIn .5s ease forwards');
	
				}                 
			});
		} 

		$('.navbar-toggler').on('click', function() {
			$('#navbarScroll').fadeIn();
			$('body').addClass('no-scroll');
			$('.navbar-close').fadeIn();
		});
		$('.navbar-close').on('click', function() {
			$('#navbarScroll').fadeOut();
			$('.navbar-close').fadeOut();
			$('body').removeClass('no-scroll');
			$('.navbar-toggler').toggle();
			$('.navbar-toggler').focus();
		});
		
	}
}
window.app.add('scroll', new scrollHelper());