window.$ = jQuery.noConflict(true);
class App {
	constructor() {
		document.addEventListener('DOMContentLoaded', () => {
			// Replace with your required fonts
			require('webfontloader').load({
				google: {
					families: ['Open Sans:100,200,300,400,500,600,700,800,900', 'Raleway:100,200,300,400,500,600,700,800,900']
				}
			});
			this.collect.bind(this)();
		});
	}
	add(propertyName, object) {
		this[propertyName] = object;
		(this[propertyName].init || function(){}).bind(this[propertyName])();
	}
	call(signature, element) {
		try {
			this.envoke(((signature) => {
				signature.shift();
				return signature;
			})(signature.split('.')))(element);
		}
		catch (Exception) {
			console.warn(`Error calling ${signature} from `, element, Exception);
		}
	}
	envoke(rest, current = this) {
		return rest.length == 1 ? current[rest.shift()].bind(current) : this.envoke(rest, current[rest.shift()]);

	}
	collect() {
		window.timeStart = window.performance.now();
		for (let element of document.querySelectorAll('[app-call]'))
			this.call(element.getAttribute('app-call'), element);
		console.info(`Execution time: ${window.performance.now() - window.timeStart}ms after DOMContentLoaded.`);
	}
}
window.app = new App();